import { styled } from "styled-components";
import { Link } from "react-router-dom";

const SimpleCallToAction = ({ height, message, mode }) => {
  const heightValue = height;
  let backgroundColor = "black";
  let fontColor = "white";

  if (mode !== "black") {
    backgroundColor = "white";
    fontColor = "black";
  }

  const CtaSection = styled.div`
    height: ${heightValue}vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${backgroundColor};

    a {
      text-decoration: underline;
      text-decoration-style: solid;
      text-decoration-color: black;
      text-underline-offset: 7px;
      text-decoration-thickness: 1px;
      transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
    }

    a:hover {
      transform: scale(1.05); /* Agrandit le lien de 20% */
    }

    @media (max-width: 768px) {
      height: ${heightValue - 5}vh;

      a {
        text-underline-offset: 4px;
      }
    }
  `;

  const CTASentence = styled.p`
    color: ${fontColor};
    font-family: "Montserrat", sans-serif;
    margin-bottom: 2em;
    font-size: 1.5em;

    @media (max-width: 768px) {
      margin-left: 10%;
      margin-right: 10%;
      font-size: 1em;
    }
  `;

  return (
    <>
      <CtaSection>
        <Link to="/contact">
          <CTASentence>{message}</CTASentence>
        </Link>
      </CtaSection>
    </>
  );
};

export default SimpleCallToAction;
