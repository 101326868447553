import React from "react";
import Univers from "./Univers";
import "./UniversList.scss";
import CallToActionWrapper from "./CallToActionWrapper";

const UniversList = ({
  listePhotosCampagne,
  listePhotosContemporain,
  listePhotosManoir,
  listePhotosPro,
}) => {
  return (
    <div className="universList">
      <Univers universName={"campagne"} listePhotos={listePhotosCampagne} />
      <Univers
        universName={"contemporain"}
        listePhotos={listePhotosContemporain}
      />
      <CallToActionWrapper
        message="Envie de créer votre univers personnalisé"
        height={"40vh"}
        mode={"white"}
        buttonColor="#283B3E"
      />

      <Univers universName={"manoir"} listePhotos={listePhotosManoir} />
      <Univers universName={"professionnel"} listePhotos={listePhotosPro} />
    </div>
  );
};

export default UniversList;
