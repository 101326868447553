import React, { useState, useRef } from "react";
import PopInNosRealisations from "./PopInNosRealisations.js";
import "./CarouselWelcomePage.scss";

const CarouselDernieresRealisations = ({ photos }) => {
  const [offset, setOffset] = useState(0);
  const [draggingIndex, setDraggingIndex] = useState(null);
  const dragStart = useRef(0);
  const isDragging = useRef(false);
  const moveSize = 100 / 3; // Déplacement de 33.33% par image

  const handleDragStart = (e, index) => {
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    dragStart.current = clientX;
    isDragging.current = true;
    setDraggingIndex(index); // Déclencher le shake pour l'image en cours de drag
  };

  const handleDragEnd = (e) => {
    isDragging.current = false;
    setDraggingIndex(null); // Arrêter le shake à la fin du drag
    const endX = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;
    const deltaX = endX - dragStart.current;
    if (Math.abs(deltaX) > window.innerWidth / 4) {
      if (deltaX < 0) {
        handleNext(true); // Swipe vers la gauche
      } else {
        handlePrev(true); // Swipe vers la droite
      }
    } else {
      const snappedOffset = Math.round(offset / moveSize) * moveSize;
      setOffset(snappedOffset);
    }
  };

  const handleNext = (isSwipe = false) => {
    const steps = isSwipe ? 3 : 1; // Avancer de trois images sur un swipe
    const maxOffset = -(photos.length - 3) * moveSize;
    setOffset(prevOffset => Math.max(prevOffset - steps * moveSize, maxOffset));
  };

  const handlePrev = (isSwipe = false) => {
    const steps = isSwipe ? 3 : 1; // Reculer de trois images sur un swipe
    setOffset(prevOffset => Math.min(prevOffset + steps * moveSize, 0));
  };

  const styleChevron = {
    backgroundImage: 'url("/img/chevron.webp")',
  };

  return (
    <div className="carousel-container-welcomePage">
      <div
        className="carousel-welcomePage"
        style={{ transform: `translateX(${offset}%)` }}
        onMouseUp={handleDragEnd}
        onTouchEnd={handleDragEnd}
      >
        {photos.map((photo, index) => (
          <div
            key={index}
            className="carousel-item-welcomePage"
            onMouseDown={(e) => handleDragStart(e, index)}
            onTouchStart={(e) => handleDragStart(e, index)}
          >
            <PopInNosRealisations photoElement={photo} isShaking={draggingIndex === index} />
          </div>
        ))}
      </div>
      {photos.length > 3 && ( // Condition rajoutée pour éviter de display les chevrons si pas necessaire
        <div className="carousel-nosrealisations-button-container">
          <button
            className="carousel-nav-button-welcomePage prev hoverCursor"
            onClick={() => handlePrev()}
            style={styleChevron}
            aria-label="Passer à l'image précedente dans le carousel"
          />
          <button
            className="carousel-nav-button-welcomePage next hoverCursor"
            onClick={() => handleNext()}
            style={styleChevron}
            aria-label="Passer à l'image suivante dans le carousel"
          />
        </div>
      )}
    </div>
  );
};

export default CarouselDernieresRealisations;
