import React from "react";
import styled from "styled-components";
import { FaPhone, FaWhatsapp, FaEnvelope } from "react-icons/fa"; // Icones FontAwesome
import { MAISON_CHARLET_PHONE, MAIL_CONTACT } from "../Common/constants";

const IconRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  padding: 10px;
  margin-bottom:1em;

  .icon {
    font-size: 32px;
    color: white;
    cursor: pointer;
    transition: transform 0.2s ease, color 0.2s ease;

    &:hover {
      transform: scale(1.2);
      color: white;
    }
  }
`;

const ContactIcons = () => {
    const whatsappUrl = `https://wa.me/${MAISON_CHARLET_PHONE}`;

  return (
    <IconRow>
      {/* Téléphone */}
      <FaPhone
        className="icon"
        onClick={() => window.location.href = `tel:${MAISON_CHARLET_PHONE}`}
      />

      {/* WhatsApp */}
      <FaWhatsapp
        className="icon"
        onClick={() => window.open(whatsappUrl, "_blank")}
      />

      {/* Email */}
      <FaEnvelope
        className="icon"
        onClick={() => window.location.href = `mailto:${MAIL_CONTACT}`}
      />
    </IconRow>
  );
};

export default ContactIcons;
