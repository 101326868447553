export const noLazyLoadPaths = [
    "/univers/campagne",
    "/univers/contemporain",
    "/univers/manoir",
    "/univers/professionnel",
    "/condense/accessoires",
    "/condense/amenagement",
    "/condense/banc",
    "/condense/console",
    "/condense/cuisine",
    "/condense/etageres",
    "/condense/table",
    "/nosrealisations-meubles-en-bois-sur-mesure"
  ];

  export const MAISON_CHARLET_PHONE = "+33667892214";

  export const MAIL_CONTACT = "maison@charlet-bois.com";