import { styled, keyframes } from "styled-components";
import logoBlack from "../img/whatsappLogo.png";
import logoWhite from "../img/logoWhatsappWhite.png"
import { Link } from "react-router-dom";

const CallToActionWrapper = ({ height, message, mode, buttonColor, buttonColorHovering }) => {
  const heightValue = height;
  let backgroundColor = "black";
  let fontColor = "white";
  let buttonBackground = buttonColor;
  let buttonFont = "black";
  let logo = logoBlack;

  if (mode !== "black") {
    backgroundColor = "white";
    fontColor = "black";
    buttonBackground = buttonColor;
    buttonFont = "white";
    logo = logoWhite;
  }

  const CtaSection = styled.div`
    height: ${heightValue};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${backgroundColor};

    a {
      text-decoration: none;
    }
  `;

  // Animation d'apparition
  const fadeIn = keyframes`
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;

  // Conteneur principal
  const CallToActionContainer = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 12px 20px;
    background-color: ${buttonBackground};
    color: ${buttonFont};
    border-radius: 30px;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, background-color 0.3s ease,
      box-shadow 0.3s ease;
    animation: ${fadeIn} 1s ease;

    &:hover {
      transform: scale(1.1);
      background-color: ${buttonColorHovering};
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
    }
  `;

  // Style pour l'image
  const WhatsAppLogo = styled.img`
    width: 30px;
    height: 30px;
    transition: transform 0.3s ease;

    ${CallToActionContainer}:hover & {
      transform: rotate(10deg) scale(1.2);
    }
  `;

  // Style pour le texte
  const Label = styled.span`
    font-size: 1em;
    font-weight: bold;
  `;

  const CTASentence = styled.p`
    color: ${fontColor};
    font-family: "Montserrat", sans-serif;
    margin-bottom: 2em;
    font-size: 1.5em;

    @media (max-width: 768px) {
      margin-left: 10%;
      margin-right: 10%;
    }
  `;

  return (
    <>
      <CtaSection>
        <CTASentence>{message}</CTASentence>
        <Link to="/contact">
          <CallToActionContainer>
            <WhatsAppLogo src={logo} alt="WhatsApp Logo" />
            <Label>Contactez-nous</Label>
          </CallToActionContainer>
        </Link>
      </CtaSection>
    </>
  );
};

export default CallToActionWrapper;
